import React, { Fragment } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { NoSession } from "../../../components/students/dashboard/hero_section/NoSession"
import LoadingSpinner from "../../../common/Loading"
import useGetLessonspaceClassroom from "../hooks/useGetLessonspaceClassroom"
import { className } from "../../../helpers/className"
import useViewport from "../../../hooks/useViewport"
import Alerter from "../../../common/alerter"

const LessonSpaceIframe = ({ loading, classroomLink, cardClass }) => {
  if (loading)
    return (
      <Col as="main" xs={24}>
        <Card className={cardClass} style={{ minHeight: "300px" }}>
          <div style={{ height: "30vh", width: "100%" }}>
            <LoadingSpinner />
          </div>
        </Card>
      </Col>
    )

  if (!classroomLink) {
    return (
      <Col as="main" xs={24}>
        <Card className={cardClass} style={{ minHeight: "300px" }}>
          <NoSession message={"There is no lessonspace session found"} />
        </Card>
      </Col>
    )
  }

  return <iframe src={classroomLink} width="100%" style={{ height: "80vh" }} title="Lesson space session" />
}

export const LessonspaceClassroomPage = () => {
  const { isMobileViewport } = useViewport()
  const scheduleId = window.location.pathname.split("/").pop()
  const { classroomLink, loading, error } = useGetLessonspaceClassroom({ scheduleId })
  const cardClass = className(isMobileViewport ? "px-3 py-4" : "", classroomLink ? "next-lesson" : "no-lesson")
  return (
    <Fragment>
      <Container fluid className="p-0 d-flex flex-column flex-grow-1">
        <Row>
          <Col className="p-0" style={{ flexGrow: 2 }}>
            <Container className="student-dashboard-content">
              {error && Alerter.error(error)}
              <Row>
                <LessonSpaceIframe loading={loading} classroomLink={classroomLink} cardClass={cardClass} />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default LessonspaceClassroomPage
