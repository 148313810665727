import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import React, { useCallback, useState } from "react"
import { renderToString } from "react-dom/server"
import StudentCard from "../studentCard"

/**
 * Creates a styled HTML element with the given styles.
 * @param {Object} styles - An object containing CSS styles.
 * @returns {HTMLElement} - The created HTML element with the applied styles.
 */
const createStyledElement = styles => {
  const element = document.createElement("div")
  Object.assign(element.style, styles)
  return element
}

/**
 * Creates a new page element styled for PDF generation.
 * @returns {HTMLElement} - The created page element.
 */
const createPage = () => {
  return createStyledElement({
    width: "100%",
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    padding: "10mm",
  })
}

/**
 * Custom hook for generating PDF documents from selected rows.
 * @param {Function} openPreviewDialog - Function to open the PDF preview dialog.
 * @returns {Object} - An object containing loading state, error state, and functions to generate card elements and PDF.
 */
const usePDFGeneration = openPreviewDialog => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [cardElements, setCardElements] = useState({ hiddenContainer: null, pages: [] })

  /**
   * Generates card elements for each selected row and organizes them into pages.
   */
  const generateCardElements = useCallback(selectedRows => {
    const cardsPerPage = 8
    const hiddenContainer = createStyledElement({
      position: "absolute",
      top: "-9999px",
      left: "-9999px",
      width: "210mm",
      height: "297mm",
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box",
      padding: "20mm",
    })
    document.body.appendChild(hiddenContainer)

    const newPages = []
    let currentPage = createPage()
    newPages.push(currentPage)
    hiddenContainer.appendChild(currentPage)

    Array.from(selectedRows).forEach(([, row], index) => {
      if (index > 0 && index % cardsPerPage === 0) {
        currentPage = createPage()
        newPages.push(currentPage)
        hiddenContainer.appendChild(currentPage)
      }

      const cardContainer = createStyledElement({
        width: "50%",
        height: "25%",
        boxSizing: "border-box",
      })

      cardContainer.innerHTML = renderToString(<StudentCard row={row} />)
      currentPage.appendChild(cardContainer)
    })

    hiddenContainer.offsetHeight

    setCardElements({ hiddenContainer, pages: newPages })
  }, [])

  /**
   * Generates a PDF document from the card elements.
   * @returns {Promise<string|null>} - A promise that resolves to the PDF URL or null if an error occurs.
   */
  const generatePDF = useCallback(async () => {
    setIsLoading(true)
    setError(null)

    const { pages } = cardElements

    try {
      const pdf = new jsPDF("p", "mm", "a4")
      for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const canvas = await html2canvas(pages[pageIndex], { scale: 2 })
        const imgData = canvas.toDataURL("image/png")
        if (pageIndex > 0) pdf.addPage()
        pdf.addImage(imgData, "PNG", 0, 0, 210, 297)
      }

      const pdfUrl = pdf.output("bloburl")
      return pdfUrl
    } catch (error) {
      setError(error)
      return null
    } finally {
      setIsLoading(false)
    }
  }, [cardElements, setIsLoading, setError])

  /**
   * Handles the PDF generation process and opens the preview dialog with the generated PDF URL.
   */
  const handleGeneratePDF = useCallback(async () => {
    const pdfUrl = await generatePDF()
    if (pdfUrl) {
      openPreviewDialog(pdfUrl)
    }
  }, [generatePDF, openPreviewDialog])

  return { isLoading, error, generateCardElements, generatePDF: handleGeneratePDF }
}

export default usePDFGeneration
