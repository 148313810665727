import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { memo } from "react"
import FilterInputs from "./Filters"

const AccordionStyled = styled(Accordion)(() => ({
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  fontFamily: "'Poppins', sans-serif",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f8f9fa",
}))

const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  height: 48,
  minHeight: 48,
}))

const StudentsDataGridToolbar = ({ filters, handleFilterChange, handleResetFilters }) => {
  return (
    <AccordionStyled defaultExpanded={false}>
      <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />} aria-controls="filter-content" id="filter-header">
        <Typography variant="subtitle1" fontWeight="700" color="text.secondary">
          Filters
        </Typography>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <FilterInputs
          filter={filters}
          handleFilterChange={handleFilterChange}
          handleResetFilters={handleResetFilters}
        />
      </AccordionDetails>
    </AccordionStyled>
  )
}

export default memo(StudentsDataGridToolbar)
