// designTokens.js
export const colors = {
  blue: "#3c00f5",
  turquoise: "#4bc9f2",
  red: "#f12212",
  pink: "#fa85d0",
  orange: "#ff6600",
  white: "#ffffff",
  black: "#030303",
  gray: "#b8b9bd",
  neutral20: "#f2f3f5",
  neutral40: "#e9ecef",
  neutral60: "#dee2e6",
  neutral80: "#ced4da",
  neutral100: "#adb5bd",
  neutral120: "#6c757d",
  neutral140: "#495057",
  neutral160: "#343a40",
  neutral180: "#212529",
  blueLight: "#e6e6ff",
  turquoiseLight: "#e6f9ff",
  redLight: "#ffe6e6",
  pinkLight: "#ffe6f9",
  orangeLight: "#fff2e6",
  orangeDark: "#dc3545",
  blueDark: "#212529",
  turquoiseDark: "#007bff",
  redDark: "#721c24",
  pinkDark: "#bd0032",
}

export const typography = {
  fontFamilyBase: "'Poppins', sans-serif",
  fontFamilyBtn: "'Campton', sans-serif",
  fontWeightRegular: 400,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  sizes: {
    h1: "30px",
    h2: "28px",
    h3: "24px",
    h4: "22px",
    h5: "20px",
    h6: "18px",
    body1: "1rem",
    btn: "0.875rem",
  },
}

export const dimensions = {
  borderRadiusSmall: 10,
  borderRadiusMedium: 50,
  borderRadiusLarge: 22,
  buttonMinWidth: "150px",
  buttonPaddingY: "11px",
  buttonPaddingX: "2rem",
  tooltipPaddingY: "0.75rem",
  tooltipPaddingX: "1rem",
  tooltipMaxWidth: "20rem",
  alertPadding: "1.25rem",
}
