import { useCallback, useEffect, useState } from "react"
import Alerter from "../../../../common/alerter"
import apiClient from "../../../../common/apiClient"

/**
 * Fetches students data from the API.
 *
 * @param {string} studentsAPIEndpoint - The endpoint to fetch students data from.
 * @param {number} pageSize - The number of students per page.
 * @param {number} pageNumber - The current page number.
 * @param {boolean} paginate - Whether to paginate the results.
 * @param {Object} [filters={}] - Optional filters to apply to the request.
 * @returns {Promise<Object>} The fetched students data, including students array, total count, per page count, and current page.
 */
const fetchStudents = async (studentsAPIEndpoint, pageSize, pageNumber, paginate, filters = {}) => {
  const response = await apiClient.get(studentsAPIEndpoint, {
    params: { per_page: pageSize, page: pageNumber + 1, paginate, ...filters },
  })
  const {
    data: { students, per_page, total_count, page },
  } = response
  return {
    students,
    totalCount: Number(total_count),
    perPage: Number(per_page),
    page: Number(page) - 1,
  }
}

/**
 * Custom hook to fetch students data with pagination and filters.
 *
 * @param {string} studentsAPIEndpoint - The endpoint to fetch students data from.
 * @param {Object} pagination - The pagination settings including pageSize, page, paginate, and filters.
 * @returns {Object} An object containing the fetched data, status, and functions to fetch data and fetch all students.
 */
const useFetchStudents = (studentsAPIEndpoint, pagination, filters) => {
  const [data, setData] = useState({ students: [], totalCount: 0 })
  const [status, setStatus] = useState({ isLoading: false, error: null })

  /**
   * Fetches students data with updated pagination and filters.
   *
   * @param {Object} [updatedPagination=pagination] - The updated pagination settings.
   * @param {Object} [filters={}] - Optional filters to apply to the request.
   */
  const fetchData = useCallback(
    async (updatedPagination = pagination) => {
      setStatus({ isLoading: true, error: null })
      try {
        const result = await fetchStudents(
          studentsAPIEndpoint,
          updatedPagination.pageSize,
          updatedPagination.page,
          updatedPagination.paginate,
          filters
        )
        setData(result)
      } catch (error) {
        setStatus({ isLoading: false, error })
        Alerter.error(`Failed to fetch Students. Error: ${error.message}`)
      } finally {
        setStatus(prevStatus => ({ ...prevStatus, isLoading: false }))
      }
    },
    [studentsAPIEndpoint, pagination, filters]
  )

  /**
   * Fetches all students data without pagination.
   *
   * @returns {Promise<Array>} An array of all student IDs.
   */
  const fetchAllStudents = useCallback(async () => {
    setStatus({ isLoading: true, error: null })
    try {
      const result = await fetchStudents(
        studentsAPIEndpoint,
        pagination.pageSize,
        pagination.page,
        false,
        pagination.filters
      )
      return result.students.map(student => student.id)
    } catch (error) {
      setStatus({ isLoading: false, error })
      Alerter.error(`Failed to fetch all Students. Error: ${error.message}`)
      return []
    } finally {
      setStatus(prevStatus => ({ ...prevStatus, isLoading: false }))
    }
  }, [studentsAPIEndpoint, pagination])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { data, status, fetchData, fetchAllStudents }
}

export default useFetchStudents
