import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import classNames from "classnames"
import React from "react"
import { NavLink, BrowserRouter as Router } from "react-router-dom"
import { NAV_CONFIG } from "../../common/constants"
import { Roles } from "../../constants";

const NavbarLinks = ({ currentUser, hasNewMessage, hasSessionRequests, hasInterviewRequests }) => {
  const userRole = currentUser?.role;
  const canViewSchoolAdminList = currentUser?.permissions?.can_view_school_admin_list;
  let navLinks = NAV_CONFIG[userRole] || []

  if(userRole === Roles.School_Admin && !canViewSchoolAdminList) {
    navLinks = NAV_CONFIG[userRole]?.filter(navItem => navItem?.name !== Roles.School_Admin)
  } 
  const newEvents = { hasNewMessage, hasSessionRequests, hasInterviewRequests }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Router className="navbar-links">
      <div
        className={classNames({
          "mobile-nav": isMobile,
          "main-nav": !isMobile,
        })}
      >
        {navLinks.map(({ path, label, hasNew }) => (
          <React.Fragment key={path}>
            <NavLink to={path} reloadDocument relative="path">
              {label}
            </NavLink>
            {hasNew && newEvents[hasNew] && <span className="red-dot">New!</span>}
          </React.Fragment>
        ))}
      </div>
    </Router>
  )
}

export default NavbarLinks
