import Dialog from "@/components/blocks/Dialog"
import { useGridApiRef } from "@mui/x-data-grid"
import React, { useCallback, useEffect, useState } from "react"
import { Button, Container } from "react-bootstrap"
import TabsNav from "../../../blocks/UserTabs"
import CSVUpload from "./CSVUpload"
import useDialogState from "./hooks/useDialogState"
import usePDFGeneration from "./hooks/usePDFGeneration"
import StudentsTable from "./StudentsTable"

const SchoolAdminsPage = ({ currentPath, currentUser }) => {
  const apiRef = useGridApiRef()
  const [selectedRows, setSelectedRows] = useState(new Map())
  const { previewDialogState, openPreviewDialog, closePreviewDialog } = useDialogState()
  const { isLoading, generatePDF, generateCardElements } = usePDFGeneration(openPreviewDialog)

  const handleDownloadPDF = useCallback(() => {
    const link = document.createElement("a")
    link.href = previewDialogState.pdfUrl
    link.download = "student_credentials.pdf"
    link.click()
    closePreviewDialog()
  }, [previewDialogState.pdfUrl, closePreviewDialog])

  const handleGeneratePDF = useCallback(() => {
    const selectedRows = apiRef.current.getSelectedRows()
    setSelectedRows(selectedRows)
    generateCardElements(selectedRows)
  }, [apiRef, generateCardElements])

  useEffect(() => {
    const unsubscribe = apiRef.current?.subscribeEvent("rowSelectionChange", handleGeneratePDF)
    return () => unsubscribe?.()
  }, [apiRef, handleGeneratePDF])

  return (
    <Container className="school-admins-dashboard-content">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="users-navbar_title m-0">Students</h2>
        <div className="flext-grow-1 d-flex justify-content-end gap-1">
          <CSVUpload />
          <Button href="/admin/sessions/new" variant="outline-primary">
            Schedule a family session
          </Button>
          <Button href="/admin/students/new" variant="outline-primary">
            New student
          </Button>
          <div className="d-flex gap-1 flex-wrap" style={{ marginLeft: "auto" }}>
            <Button variant="primary" onClick={generatePDF} disabled={selectedRows.size === 0}>
              {isLoading ? "Generating..." : "Print Credentials"}
            </Button>
          </div>
        </div>
      </div>
      <div className="container panel-wrapper">
        <div className="panel">
          <div className="panel_header">
            <TabsNav currentUser={currentUser} currentPath={currentPath} />
          </div>
          <div className="panel_body p-0">
            <StudentsTable apiRef={apiRef} handleGeneratePDF={handleGeneratePDF} />
          </div>
        </div>
      </div>
      <Dialog size="xl" title="PDF Preview" open={previewDialogState.open} onClose={closePreviewDialog}>
        {previewDialogState.pdfUrl && (
          <iframe
            src={previewDialogState.pdfUrl}
            width="100%"
            height="600px"
            title="PDF Preview - Student Credentials"
          />
        )}
        <div className="d-flex justify-content-end gap-2 w-100 mt-3">
          <Button size="sm" variant="outline-primary" onClick={closePreviewDialog}>
            Close
          </Button>
          <Button size="sm" variant="primary" onClick={handleDownloadPDF}>
            Download PDF
          </Button>
        </div>
      </Dialog>
    </Container>
  )
}

export default SchoolAdminsPage
