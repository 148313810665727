import React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import Alerter from "../../../common/alerter"
import Navbar from "../../Navbar"
import { STUDENTS_DASHBOARD_TEXTS } from "./constants"
import { NextSession } from "./hero_section/NextSession"
import { NoSession } from "./hero_section/NoSession"
import { StudentSchedule } from "./Schedule"
import useFetchStudent from "./hooks/useFetchStudent"

export const StudentDashboardPage = ({ student_id, sign_out_button }) => {
  const { studentData, cardClass, nextSession, canStartNextSession, doesNextSessionExist, isLessonspace } =
    useFetchStudent({
      student_id,
    })

  if (!studentData) return null

  const handleStartSession = () => {
    Alerter.info("Starting session...")

    if (isLessonspace) {
      window.open(`/students/dashboard/classroom/${nextSession?.schedule_id}?student_id=${student_id}`, "_blank")
    } else {
      window.open(nextSession.address, "_blank")
    }
  }

  return (
    <>
      <header className="header">
        <Navbar user={studentData} sign_out_button={sign_out_button} />
      </header>
      <Container fluid className="p-0 d-flex flex-column flex-grow-1">
        <Row>
          <Col className="p-0">
            <h2 className="student-dashboard-title mb-4 pl-2">
              {STUDENTS_DASHBOARD_TEXTS.GREETINGS_TEXT} <span>{studentData.name.split(", ")[1]}!</span>
            </h2>
            <Container className="student-dashboard-content">
              <Row>
                <Col as="main" xs={24}>
                  <Card className={cardClass}>
                    {doesNextSessionExist ? (
                      <NextSession
                        nextSession={nextSession}
                        canStartNextSession={canStartNextSession}
                        handleStartSession={handleStartSession}
                      />
                    ) : (
                      <NoSession />
                    )}
                  </Card>
                </Col>
              </Row>
              <Row className="px-2">
                <StudentSchedule id={student_id} />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  )
}