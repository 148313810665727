import moment from "moment"
import React, { Fragment } from "react"
import { Button } from "@mui/material"
import { MeetingFormats } from "../../../constants"
import { useGetUpcomingSession } from "../hooks/useGetUpcomingSession"

export const NextSession = ({ tutorId }) => {
  const { loading, data: upcomingSessionData } = useGetUpcomingSession({ tutorId })
  const scheduleId = upcomingSessionData?.next_session?.schedule_id
  const sessionStartsIn = upcomingSessionData?.next_session?.formatted_scheduled_at_text
  const sessionScheduledAt = upcomingSessionData?.next_session?.scheduled_at
  const location = upcomingSessionData?.next_session?.location
  const address = upcomingSessionData?.next_session?.address
  const canStartNextSession = moment(sessionScheduledAt).isBefore(moment().add(5, "minutes"))
  const isOfflineSession = location === MeetingFormats.Offline

  const onJoinClassClick = e => {
    e.preventDefault()

    if (location === MeetingFormats.Online) {
      window.open(address, "_blank")
    }

    if (location === MeetingFormats.LessonSpace && scheduleId) {
      window.open(`/tutor/dashboard/classroom/${scheduleId}`, "_blank")
    }
  }

  return (
    <Fragment>
      {sessionStartsIn && !loading ? (
        <div className="panel panel_body pt-4 min-h-90-px d-block">
          <div className="d-flex justify-content-between">
            <div className="flex-1">
              <h4>
                Your next session begins in <span style={{ color: "#3c00f5" }}>{sessionStartsIn}</span>
              </h4>
              {isOfflineSession && (
                <ul>
                  <li>
                    <b>Address: {address}</b>
                  </li>
                </ul>
              )}
            </div>
            {!isOfflineSession && (
              <div className="flex-1">
                <Button
                  size="sm"
                  variant="contained"
                  onClick={onJoinClassClick}
                  disabled={isOfflineSession || !canStartNextSession}
                >
                  Join Class
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </Fragment>
  )
}
