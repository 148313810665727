import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react"
import {
  ApiContext,
  ConfigurationContext,
  DataContext,
  DataContextActions,
  DomainTransactionContext,
  InitialLoadingContext,
  QueryContext,
  QueryContextState,
  RequestsParamsCacheContext,
} from "../contexts"
import { useKeyBuilder } from "../helpers/keyBuilder"
import useQueryList from "../hooks/useQueryList"
import theme from "../library/theme"

const reducer = (state, action) => ({ ...state, [action.name]: action.data })

const RequestsParamsCacheProvider = ({ children, connectToParent = false }) => {
  const { setCache: setParentCache, getCache: getParentCache } = useContext(RequestsParamsCacheContext)
  const [state, dispatch] = useReducer(reducer, {})
  const setCache = useCallback(
    (name, data) => (connectToParent ? setParentCache(name, data) : dispatch({ name, data })),
    [connectToParent, setParentCache]
  )
  const getCache = useCallback(
    name => (connectToParent ? getParentCache(name) : state[name]),
    [connectToParent, getParentCache, state]
  )
  return (
    <RequestsParamsCacheContext.Provider value={{ setCache, getCache }}>{children}</RequestsParamsCacheContext.Provider>
  )
}

const QueryProvider = ({ children, useHandlersBuilder, connectToParent = false }) => {
  const { current: configs } = useContext(ConfigurationContext)
  const handlers = useHandlersBuilder()

  const [queryListState, getQueryState] = useQueryList({ configs, handlers, connectToParent })

  const getLoading = useCallback(params => getQueryState(params)?.loading, [getQueryState])
  const getRequest = useCallback(params => getQueryState(params)?.request, [getQueryState])
  const getFailed = useCallback(params => getQueryState(params)?.failed, [getQueryState])
  const getRetry = useCallback(params => getQueryState(params)?.retry, [getQueryState])
  const getHookState = useCallback(
    params => {
      const hookState = getQueryState(params)
      if (!hookState || Object.keys(hookState).length === 0) return {}
      else {
        const { loading, request, failed, complete } = hookState
        return { loading, request, failed, complete }
      }
    },
    [getQueryState]
  )
  return (
    <QueryContextState.Provider value={queryListState}>
      <QueryContext.Provider value={{ getQueryState, getHookState, getLoading, getRequest, getFailed, getRetry }}>
        {children}
      </QueryContext.Provider>
    </QueryContextState.Provider>
  )
}

const StatelessDataProvider = ({ initialState, children }) => {
  const parentContextActions = useContext(DataContextActions)

  const updateState = useCallback(
    (config, data) => {
      parentContextActions.updateState(config, data, {
        updateState: parentContextActions.updateState,
        getState: parentContextActions.getState,
      })
    },
    [parentContextActions]
  )

  const getState = useCallback(config => parentContextActions.getState(config), [parentContextActions])

  useEffect(() => {
    return initialState ? parentContextActions.setPartialState(initialState, true) : void 0
  }, [initialState, parentContextActions])

  return (
    <DataContextActions.Provider value={{ ...parentContextActions, updateState, getState }}>
      {children}
    </DataContextActions.Provider>
  )
}

const StatefulDataProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const keyBuilder = useKeyBuilder()

  const resetFields = useCallback(
    fields => {
      fields.forEach(name => {
        if (state[name]) dispatch({ name, data: void 0 })
      })
    },
    [state]
  )

  const setPartialState = useCallback(
    partialState => {
      Object.keys(partialState).forEach(name => {
        if (state[name] === void 0) dispatch({ name, data: partialState[name] })
      })

      return () => resetFields(Object.keys(partialState))
    },
    [resetFields, state]
  )

  const updateState = useCallback(
    (config, data) => {
      const { alias, skipStorage, onStoreUpdate = data => data } = config
      if (skipStorage) return
      const name = alias || keyBuilder(config)
      dispatch({ name, data: onStoreUpdate(data, { updateState, getState }) })
    },
    [getState, keyBuilder]
  )

  const getState = useCallback(
    config => {
      const key = (typeof config === "string" ? config : config.alias) || keyBuilder(config)
      return state[key]
    },
    [keyBuilder, state]
  )

  return (
    <DataContext.Provider value={state}>
      <DataContextActions.Provider value={{ setPartialState, updateState, resetFields, getState }}>
        {children}
      </DataContextActions.Provider>
    </DataContext.Provider>
  )
}

const LayerDataProvider = ({ initialState, connectToParent = false, children }) => {
  const parentData = useContext(DataContext)
  const [state, dispatch] = useReducer(reducer, {})
  const parentActions = useContext(DataContextActions)
  const { current: currentConfigs } = useContext(ConfigurationContext)
  const keyBuilder = useKeyBuilder()

  const resetFields = useCallback(
    fields => {
      if (fields.length === 0) return
      fields.filter(name => state[name]).forEach(name => dispatch({ name, data: void 0 }))
      if (connectToParent) {
        const rest = fields.filter(name => !state[name])
        parentActions.resetFields(rest)
      }
    },
    [connectToParent, parentActions, state]
  )

  const setPartialState = useCallback(
    (partialState, initial) => {
      if (connectToParent) return parentActions.setPartialState(partialState, initial)
      else {
        const names = []
        Object.keys(partialState).forEach(name => {
          if (initial && state[name] !== void 0) return
          dispatch({ name, data: partialState[name] })
          names.push(name)
        })
        return () => resetFields(names)
      }
    },
    [connectToParent, parentActions, resetFields, state]
  )

  const updateState = useCallback(
    (config, data) => {
      let alias
      let skipStorage = false
      let onStoreUpdate = data => data
      if (typeof config === "string") alias = config
      else {
        if (config.alias) alias = config.alias
        if (config.skipStorage) skipStorage = config.skipStorage
        if (config.onStoreUpdate) onStoreUpdate = config.onStoreUpdate
      }
      if (skipStorage) return
      if (connectToParent && !currentConfigs.includes(config)) {
        parentActions.updateState(config, data)
        return
      }
      const name = alias || keyBuilder(config)
      dispatch({ name, data: onStoreUpdate(data, { updateState, getState }) })
    },
    [connectToParent, currentConfigs, getState, keyBuilder, parentActions]
  )

  const getState = useCallback(
    config => {
      const key = (typeof config === "string" ? config : config.alias) || keyBuilder(config)
      return { ...parentData, ...state }[key]
    },
    [keyBuilder, parentData, state]
  )

  useEffect(() => {
    if (initialState) {
      return setPartialState(initialState, true)
    }
  }, [initialState, setPartialState])

  return (
    <DataContext.Provider value={{ ...parentData, ...state }}>
      <DataContextActions.Provider value={{ updateState, setPartialState, resetFields, getState }}>
        {children}
      </DataContextActions.Provider>
    </DataContext.Provider>
  )
}

const DataProvider = ({ type, connectToParent, initialState, children }) => {
  switch (type) {
    case "singleSource":
      return connectToParent ? (
        <StatelessDataProvider initialState={initialState}>{children}</StatelessDataProvider>
      ) : (
        <StatefulDataProvider initialState={initialState}>{children}</StatefulDataProvider>
      )
    case "layerSource":
    default:
      return (
        <LayerDataProvider connectToParent={connectToParent} initialState={initialState}>
          {children}
        </LayerDataProvider>
      )
  }
}

const Request = ({ config, transaction }) => {
  const { getQueryState } = useContext(QueryContext)
  const { getState } = useContext(DataContextActions)
  const exist = useMemo(() => Boolean(getState(config)), [config, getState])
  const query = useMemo(() => getQueryState(config), [config, getQueryState])
  const initialRequestParams = useMemo(() => config.initialParamsBuilder(getState), [config, getState])

  useEffect(() => {
    if (!query || query.complete || query.failed || query.loading || !query.request) return
    if (config.skipIfExist && exist) return
    query.request({ ...initialRequestParams, transaction })
  }, [config, initialRequestParams, query, exist, transaction])

  return null
}

const InitialRequests = ({ children }) => {
  const { initial } = useContext(ConfigurationContext)
  const { buildTransaction } = useContext(DomainTransactionContext)
  const transaction = useMemo(
    () =>
      buildTransaction({
        autoCommitOn: initial.length,
      }),
    [buildTransaction, initial]
  )
  return (
    <>
      {initial.map((config, idx) => (
        <Request key={idx} config={config} transaction={transaction} />
      ))}
      {children}
    </>
  )
}

const InitialLoadingProvider = ({ children }) => {
  const parent = useContext(InitialLoadingContext)
  const { initial: config } = useContext(ConfigurationContext)
  const { getState } = useContext(DataContextActions)

  const initialLoading = useMemo(
    () => config.reduce((acc, config) => getState(config) === void 0 || acc, parent.initialLoading || false, false),
    [config, getState, parent.initialLoading]
  )

  const result = {
    initialLoading,
  }

  return <InitialLoadingContext.Provider value={result}>{children}</InitialLoadingContext.Provider>
}

const ConfigurationProvider = ({ config = [], children }) => {
  const { common, current: parent = [] } = useContext(ConfigurationContext)
  common.push(...config)
  const initial = useMemo(() => config.filter(({ initialParamsBuilder }) => Boolean(initialParamsBuilder)), [config])
  return (
    <ConfigurationContext.Provider value={{ common, initial, parent, current: config }}>
      {children}
    </ConfigurationContext.Provider>
  )
}

const ApiProvider = ({ children, apiBase, useApiPathBuilder }) => {
  const { base } = useContext(ApiContext)
  const current = useApiPathBuilder(apiBase)
  return <ApiContext.Provider value={{ base, current }}>{children}</ApiContext.Provider>
}

const DomainTransactionContextProvider = ({ children }) => {
  const [prevState, setPrevState] = useState({})
  const store = useContext(DataContext)
  const { setPartialState } = useContext(DataContextActions)
  const parentContext = useContext(DomainTransactionContext)
  const stateRef = useRef(new Map())

  const start = useCallback(
    id => {
      setPrevState(store)
      let counter = 1
      let list = []
      if (stateRef.current.has(id)) {
        const [savedCounter, savedList] = stateRef.current.get(id)
        counter = savedCounter + 1
        list = savedList
      }
      stateRef.current.set(id, [counter, list])
    },
    [store]
  )

  const add = useCallback(
    async (id, fn, commitConfig) => {
      // eslint-disable-next-line no-console
      if (!stateRef.current.has(id)) return void console.warn("Transaction not found")

      const [counter, list] = stateRef.current.get(id)
      list.push(fn)
      stateRef.current.set(id, [counter, list])

      await commit(id, commitConfig)
    },
    [commit]
  )

  const rollback = useCallback(
    id => {
      setPartialState(prevState)
      setPrevState({})
      stateRef.current.set(id, [0, []])
    },
    [prevState, setPartialState]
  )

  const destroy = useCallback(id => {
    stateRef.current.delete(id)
  }, [])

  const commit = useCallback(
    async (id, config = {}) => {
      const { manualCommitControl = false, destroyAfterCommit = true, force = false } = config
      // eslint-disable-next-line no-console
      if (!stateRef.current.has(id)) return void console.warn("Transaction not found")

      const [counter, list] = stateRef.current.get(id)
      if (force || counter === list.length) {
        // eslint-disable-next-line no-console
        if (!list.every(fn => typeof fn === "function")) return void console.warn("Transaction should be a function")
        try {
          if (!manualCommitControl) await Promise.all(list.map(async fn => await fn()))
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("ROLLBACK: Some of transaction commits couldn't be finished.", e.message)
          rollback()
        } finally {
          if (destroyAfterCommit) destroy(id)
        }
      }
    },
    [destroy, rollback]
  )

  const buildTransaction = useCallback(
    (config = {}) => {
      const { name = Symbol(), manualCommitControl: commitControl, ...rest } = config
      return Object.freeze({
        start: () => start(name),
        add: fn => add(name, fn, rest),
        rollback: () => rollback(name),
        destroy: () => destroy(name),
        commit: (config = {}) => {
          const { manualCommitControl = !commitControl, ...restConfig } = config
          commit(name, {
            ...rest,
            ...restConfig,
            manualCommitControl,
          })
        },
      })
    },
    [add, commit, destroy, rollback, start]
  )

  const value = Object.keys(parentContext).length ? parentContext : { buildTransaction }

  return <DomainTransactionContext.Provider value={value}>{children}</DomainTransactionContext.Provider>
}

// config interface:
// [{
//   entities: [Entities.GroupSessions],
//   action?: "status",
//   method?: Methods.Get,
//   permissions?: { allow?: [], deny?: [] },
//   alias?: "statusInfo",
//   initialParamsBuilder?: (getState) => { */ request params /* },
//   skipStorage?: false,
//   proxify?: async (request) => await request(config) ???
//   onStoreUpdate?: (data) => data,
//   onComplete?: (data) => data,
//   onSuccess?: (data) => data,
//   onFailure?: (data) => data,
//   next?: [partialConfElements]
// }]

export const DomainProvider = ({
  config,
  apiBase,
  children,
  dataProviderType,
  initialStorageState = {},
  connectToParentStore = false,
  useApiPathBuilder = () => "",
  useHandlersBuilder = () => ({}),
}) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfigurationProvider config={config}>
        <DataProvider type={dataProviderType} initialState={initialStorageState} connectToParent={connectToParentStore}>
          <DomainTransactionContextProvider>
            <RequestsParamsCacheProvider connectToParent={connectToParentStore}>
              <ApiProvider apiBase={apiBase} useApiPathBuilder={useApiPathBuilder}>
                <QueryProvider useHandlersBuilder={useHandlersBuilder} connectToParent={connectToParentStore}>
                  <InitialLoadingProvider>
                    <InitialRequests>{children}</InitialRequests>
                  </InitialLoadingProvider>
                </QueryProvider>
              </ApiProvider>
            </RequestsParamsCacheProvider>
          </DomainTransactionContextProvider>
        </DataProvider>
      </ConfigurationProvider>
    </ThemeProvider>
  )
}

export default DomainProvider
