import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import LoadingSpinner from "../../../common/loading"
import useFetchStudents from "./hooks/useFetchStudents"
import StudentsTable from "./studentsTable"
import AuthenticationModal from "./AuthenticationModal"

const INITIAL_PAGINATION = { pageSize: 20, page: 0, paginate: true }
const INITIAL_DENSITY = "compact"

export const SchoolAdminStudentsPage = ({ studentsAPIEndpoint, authenticationEndpoint, canViewStudentList }) => {
  const [open, setOpen] = useState(!canViewStudentList)
  const [pagination, setPagination] = useState(INITIAL_PAGINATION)
  const [filters, setFilters] = useState()
  const [density, setDensity] = useState(INITIAL_DENSITY)

  const {
    data: { students, google_sso_enabled, totalCount } = {},
    status: { isLoading, error },
    fetchData,
  } = useFetchStudents(studentsAPIEndpoint, pagination, filters)

  const handlePageChange = useCallback(({ pageSize, page }) => {
    setPagination(prev => ({ ...prev, page, pageSize }))
  }, [])

  const handleDensityChange = useCallback(density => {
    setDensity(density)
  }, [])

  const handleFilterChange = useCallback(({ items }) => {
    const { field, value } = items?.[0]
    setFilters({ [field]: value })
  }, [])

  useEffect(() => {
    fetchData(pagination, filters)
  }, [pagination, fetchData, filters])

  const tableProps = useMemo(
    () => ({
      students: students || [],
      totalCount: totalCount || 0,
      pagination,
      filters,
      density,
      google_sso_enabled,
      isLoading,
      onDensityChange: handleDensityChange,
      onPageChange: handlePageChange,
      onFilterChange: handleFilterChange,
      fetchData,
    }),
    [
      students,
      totalCount,
      pagination,
      filters,
      density,
      google_sso_enabled,
      isLoading,
      handleDensityChange,
      handlePageChange,
      handleFilterChange,
      fetchData,
    ]
  )

  return (
    <Container fluid className="p-0 d-flex flex-column flex-grow-1">
      {!open ? (
        <Row>
          <Col className="p-0">
            <h2 className="users-navbar_title mb-4 pl-2">Students</h2>
            <Container className="school-admins-dashboard-content">
              <Row>
                <Col as="main" xs={24}>
                  <Card className="px-3 py-4">
                    <div style={{ height: "100%", width: "100%" }}>
                      {isLoading && !students ? <LoadingSpinner /> : <StudentsTable {...tableProps} />}
                      {error && <div className="text-danger">Error: {error.message}</div>}
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      ) : (
        <AuthenticationModal endpoint={authenticationEndpoint} setOpen={setOpen} />
      )}
    </Container>
  )
}
