export const noEventDayStatus = "tile"

export const SessionStatuses = Object.freeze({
  Pending: "pending",
  Finished: "finished",
  ReportReady: "report_ready",
  ReportSent: "report_sent",
  Cancelled: "cancelled",
  CancelledNoShow: "cancelled_no_show",
})

export const finalStatuses = Object.freeze([
  SessionStatuses.Finished,
  SessionStatuses.Cancelled,
  SessionStatuses.CancelledNoShow,
])

export const ReportStatuses = Object.freeze({
  Sent: "report_sent",
  Submitted: "report_submitted",
  Overdue: "report_overdue",
  Planned: "planned",
  InProgress: "in_progress",
  NoSessions: "no_sessions",
  Abandoned: "report_abandoned",
})

export const cancelledStatuses = Object.freeze(finalStatuses.filter(status => status !== SessionStatuses.Finished))

export const reportValidationStatuses = Object.freeze([ReportStatuses.InProgress, ReportStatuses.Overdue])

export const routerOptions = Object.freeze({ basename: "/schedules" })

export const ValidationNames = Object.freeze({
  Agenda: "agenda",
  NextSteps: "nextSteps",
  StrengthsChallenges: "strengthsChallenges",
  ProgramProgresses: "programProgresses",
  StudentStrengthsChallenges: "studentStrengthsChallenges",
  StudentProgramProgresses: "studentProgramProgresses",
  GroupProgressInfo: "groupProgressInfo",
  GroupProgressInfoInvalid: "groupProgressInfoInvalid",
  AllSessionFinished: "allSessionFinished",
  AttendanceWarnings: "attendanceWarnings",
  CommonInvalid: "commonInvalid",
  EmptySessions: "emptySessions",
  FutureReport: "futureReport",
})

export const DayActions = Object.freeze({
  CreateSession: "create_session",
})

export const SessionActions = Object.freeze({
  Update: "update",
  Destroy: "destroy",
  Finish: "finish",
  Cancel: "cancel",
  Pend: "pend",
})

export const ScheduleActions = Object.freeze({
  Update: "update",
  ManageSessions: "manage_sessions",
})

export const SessionTitles = Object.freeze({
  [SessionStatuses.Pending]: "Pending",
  [SessionStatuses.Finished]: "Finished",
  [SessionStatuses.Cancelled]: "Canceled",
  [SessionStatuses.CancelledNoShow]: "No show",
})

export const MeetingFormatTitles = Object.freeze({
  online: "Online",
  offline: "Offline",
  pencil_spaces: "Pencil Spaces",
})

export const EducatableTypes = Object.freeze({
  Group: "OpeningGroup",
  Student: "Student",
})

export const ConnectionLevels = Object.freeze({
  Main: "main",
  Substitute: "substitute",
})
