import React from "react"
import { InputAdornment, ListSubheader, MenuItem, Select, TextField } from "@mui/material"
import { useMemo } from "react"
import SearchIcon from "@mui/icons-material/Search"

const SelectWithSearchInput = ({
  id,
  name,
  placeholder,
  value,
  style,
  defaultValue,
  handleOnChangeSelect,
  optionsList,
  showSearchBar = true,
  searchText,
  setSearchText,
}) => {
  const currentOptionsList = useMemo(
    () => optionsList.filter(item => item?.title?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1),
    [optionsList, searchText]
  )

  return (
    <Select
      id={id}
      value={value}
      className="mb-0 mt-n1 form-input-align form-control flex-column form-group col-lg-16 form-control"
      label=""
      name={name}
      style={{
        paddingRight: 0,
        paddingLeft: "2em",
        lineHeight: "0.4375em",
        borderRadius: "40px",
        ...style,
      }}
      sx={{
        "& .MuiInputBase-input": {
          paddingTop: "13px",
        },
      }}
      defaultValue={defaultValue}
      onClose={() => setSearchText && setSearchText("")}
      onChange={handleOnChangeSelect}
    >
      {showSearchBar && setSearchText && (
        <ListSubheader>
          <TextField
            size="small"
            autoFocus
            placeholder={placeholder}
            fullWidth
            onBlur={e => e.target.focus()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={e => setSearchText(e.target.value)}
            onKeyDown={e => {
              if (e.key !== "Escape") {
                e.stopPropagation()
              }
            }}
          />
        </ListSubheader>
      )}
      {currentOptionsList.map(item => (
        <MenuItem key={item.value} value={item.value} title={item.title}>
          {item.title}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectWithSearchInput
