import React, { useState, useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { ReactComponent as EyeIcon } from "../../assets/images/eye.svg"
import { ReactComponent as TrainingCenter } from "../../assets/images/training_center.svg"
import { useGetAdminMessageRequest, useTutorScheduleRequest } from "./hooks"
import AdminsMessage from "./AdminsMessage"
import WeekSchedule from "./WeekSchedule"
import { formatDate } from "../../helpers/dates"
import { UserDashboard } from "./UserDashboard"
import { OpportunitiesList } from "./OpportunitiesList"
import { TutorDashboardProvider } from "./providers"
import { NextSession } from "./NextSession"

const links = [
  {
    icon: EyeIcon,
    href: "https://docs.google.com/document/d/11XBrI-KHUp_DzgO13y8TSU3Lh1sqKZ4vZjamzI-IZ9o/export?format=pdf",
    title: "Guide to Braintrust",
  },
  {
    icon: TrainingCenter,
    href: "https://k12.instructure.com/enroll/A7TDKC",
    title: "Training Center",
  },
]

const LinkRow = ({ className, icon: Icon, href, title }) => {
  return (
    <Row className={`align-items-center ${className}`}>
      <div className="mr-1 dashboard_link-icon d-flex align-items-center justify-content-center">
        <Icon />
      </div>
      <a href={href} target="_blank" className="font-weight-medium" rel="noreferrer">
        {title}
      </a>
    </Row>
  )
}

const LinksPanel = () => {
  return (
    <div className="panel panel_body w-100 px-5 py-4 mb-4">
      <Col>
        {links.map((linkData, idx, arr) => (
          <LinkRow key={idx} {...linkData} className={idx === arr.length - 1 ? "" : "mb-2"} />
        ))}
      </Col>
    </div>
  )
}

const TutorDashboardContent = ({ currentUser, calendarPath, links }) => {
  const userName = currentUser?.first_name;
  const tutorId = currentUser?.id;
  const [selectedDate, setSelectedDate] = useState(() => formatDate(new Date()))
  const updateSelectedDate = date => {
    setSelectedDate(() => formatDate(date))
  }

  const { loading: adminMessageLoading, data: adminMessage } = useGetAdminMessageRequest()

  const {
    loading: tutorScheduleLoading,
    data: tutorScheduleEvents,
    request: tutorScheduleRequest,
  } = useTutorScheduleRequest()

  useEffect(() => {
    tutorScheduleRequest(selectedDate)
  }, [tutorScheduleRequest, selectedDate])

  const globalLoading = adminMessageLoading && tutorScheduleLoading

  if (globalLoading) {
    return (
      <div className="spinner-wrapper">
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  return (
    <>
      <div className="d-flex flex-column">
        <Col>
          <WeekSchedule
            title={`Welcome back, ${userName}!`}
            loading={tutorScheduleLoading}
            week={selectedDate}
            setWeek={updateSelectedDate}
            events={tutorScheduleEvents}
            calendarPath={calendarPath}
          />
        </Col>
        <Col className="container panel-wrapper">
          <NextSession tutorId={tutorId} />
        </Col>
        <Col>
          <Row>
            <Col sx={24} lg={7} className="dashboard_tutor_message -mobile">
              <Row>
                <Col>
                  <AdminsMessage message={adminMessage} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <LinksPanel tutorGuide={links.guide} askQuestion={links.ask} />
                </Col>
              </Row>
            </Col>
            <Col lg={15} xl={16} className="container panel-wrapper">
              <OpportunitiesList />
            </Col>
            <Col sx={24} lg={7} className="container panel-wrapper dashboard_tutor_message -desktop ml-5">
              <div className="dashboard_tutor_message_wrapper">
                <Row>
                  <Col>
                    <UserDashboard />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AdminsMessage message={adminMessage} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <LinksPanel tutorGuide={links.guide} askQuestion={links.ask} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </div>
    </>
  )
}

const TutorDashboard = props => {
  return (
    <TutorDashboardProvider>
      <TutorDashboardContent {...props} />
    </TutorDashboardProvider>
  )
}

export default TutorDashboard
