import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid"
import React, { memo, useCallback, useEffect } from "react"
import { Button } from "react-bootstrap"
import Dialog from "../../blocks/Dialog"
import useDialogState from "./hooks/useDialogState"
import usePDFGeneration from "./hooks/usePDFGeneration"

/**
 * Toolbar component for the Students Data Grid.
 * Provides functionality to select/deselect all rows, generate PDFs, and preview/download the generated PDFs.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.rowSelectionModel - The current row selection model.
 * @param {Function} props.setRowSelectionModel - Function to update the row selection model.
 * @param {Array} props.rows - The rows data.
 * @param {boolean} props.google_sso_enabled - Flag indicating if Google SSO is enabled.
 * @returns {JSX.Element} The StudentsDataGridToolbar component.
 */
const StudentsDataGridToolbar = ({ rowSelectionModel, setRowSelectionModel, rows, google_sso_enabled }) => {
  const apiRef = useGridApiContext()
  const selectedRows = apiRef.current?.getSelectedRows() || []

  const { previewDialogState, openPreviewDialog, closePreviewDialog } = useDialogState()
  const { isLoading, generatePDF, generateCardElements } = usePDFGeneration(
    selectedRows,
    google_sso_enabled,
    openPreviewDialog
  )

  /**
   * Handles the selection of all rows in the data grid.
   * Updates the row selection model with all row IDs.
   */
  const handleSelectAll = useCallback(() => {
    const allRowIds = apiRef.current?.getAllRowIds() || []
    setRowSelectionModel(allRowIds)
  }, [apiRef, setRowSelectionModel])

  /**
   * Handles the deselection of all rows in the data grid.
   * Clears the row selection model.
   */
  const handleUnselectAll = useCallback(() => {
    setRowSelectionModel([])
  }, [setRowSelectionModel])

  const handleDownloadPDF = useCallback(() => {
    const link = document.createElement("a")
    link.href = previewDialogState.pdfUrl
    link.download = "student_credentials.pdf"
    link.click()
    closePreviewDialog()
  }, [previewDialogState.pdfUrl, closePreviewDialog])

  useEffect(() => {
    const unsubscribe = apiRef.current?.subscribeEvent("rowSelectionChange", generateCardElements)
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [apiRef, generateCardElements])

  return (
    <div className="d-flex justify-content-between border-bottom students-data-grid-toolbar gap-1">
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector slotProps={{ tooltip: { title: "Change density" } }} />
      </GridToolbarContainer>
      <div className="d-flex gap-1 flex-wrap" style={{ marginLeft: "auto" }}>
        <Button size="sm" variant="outline-primary" onClick={handleSelectAll} disabled={!rows.length}>
          Select All
        </Button>
        <Button size="sm" variant="outline-primary" onClick={handleUnselectAll} disabled={!rowSelectionModel.length}>
          Deselect All
        </Button>
        <Button size="sm" variant="primary" onClick={generatePDF} disabled={!rows.length || !rowSelectionModel.length}>
          {isLoading ? "Generating..." : "Print Credentials"}
        </Button>
      </div>

      <Dialog size="xl" title="PDF Preview" open={previewDialogState.open} onClose={closePreviewDialog}>
        {previewDialogState.pdfUrl && (
          <iframe
            src={previewDialogState.pdfUrl}
            width="100%"
            height="600px"
            title="PDF Preview - Student Credentials"
          />
        )}
        <div className="d-flex justify-content-end gap-2 w-100 mt-3">
          <Button size="sm" variant="outline-primary" onClick={closePreviewDialog}>
            Close
          </Button>
          <Button size="sm" variant="primary" onClick={handleDownloadPDF}>
            Download PDF
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

export default memo(StudentsDataGridToolbar)
