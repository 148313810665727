import apiClient from "@/common/apiClient"
import BraintrustDataGrid from "@/common/DataGrid"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import StudentsDataGridToolbar from "./studentsDataGridToolbar"

/**
 * Transforms student data into rows for the DataGrid component.
 *
 * @param {Object} student - Student object.
 * @returns {Object} Transformed student data as a row.
 */
const transformStudentData = student => ({
  ...student,
  id: student.id,
  name: `${student.first_name} ${student.last_name}`,
})

const INITIAL_PAGINATION = { pageSize: 20, page: 0, paginate: true }
const INITIAL_FILTERS = {
  name: "",
  grade: "",
  school_id: "",
  parent: "",
  group_id: "",
  assignments: "",
}

const fetchStudents = async (studentsAPIEndpoint, pageSize, pageNumber, paginate, filters = {}) => {
  const response = await apiClient.get(studentsAPIEndpoint, {
    params: { per_page: pageSize, page: pageNumber + 1, paginate, ...filters },
  })
  const {
    data: { students, per_page, total_count, page },
  } = response
  return {
    students,
    totalCount: Number(total_count),
    perPage: Number(per_page),
    page: Number(page) - 1,
  }
}

const StudentsTable = ({ apiRef }) => {
  const [data, setData] = useState({ students: [], totalCount: 0, perPage: 20, page: 0 })
  const [status, setStatus] = useState({ isLoading: false, error: null })
  const [pagination, setPagination] = useState(INITIAL_PAGINATION)
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const [density, setDensity] = useState("compact")

  const handlePageChange = useCallback(({ pageSize, page }) => {
    setPagination(({ ...prev }) => ({ ...prev, page, pageSize }))
  }, [])

  const handleDensityChange = useCallback(density => {
    setDensity(density)
  }, [])

  const handleFilterChange = useCallback(e => {
    const { name, value } = e.target
    const newFilter = {
      [name]: value,
    }
    setFilters(prevFilters => ({ ...prevFilters, ...newFilter }))
  }, [])

  const handleResetFilters = useCallback(() => {
    setFilters(INITIAL_FILTERS)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setStatus({ isLoading: true, error: null })
      try {
        const result = await fetchStudents(
          "/api/v2/admins/students",
          pagination.pageSize,
          pagination.page,
          pagination.paginate,
          filters
        )
        setData(result)
      } catch (error) {
        setStatus({ isLoading: false, error })
      } finally {
        setStatus(prevStatus => ({ ...prevStatus, isLoading: false }))
      }
    }

    fetchData()
  }, [pagination, filters])

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        headerClassName: "table-header",
        resizable: false,
        flex: 1,
        renderCell: params => (
          <a href={`/school_admin/students/${params.row.id}`} style={{ color: "#3c00f5", textDecoration: "none" }}>
            {params.row.name}
          </a>
        ),
      },
      {
        field: "grade",
        headerName: "Grade",
        headerClassName: "table-header",
        resizable: false,
        flex: 1,
        filterable: true,
      },
      {
        field: "school",
        headerName: "School",
        headerClassName: "table-header",
        resizable: false,
        flex: 1,
        filterable: true,
      },
      {
        field: "parent",
        headerName: "Parent",
        headerClassName: "table-header",
        resizable: false,
        flex: 1,
        filterable: true,
      },
      {
        field: "groups",
        headerName: "Groups",
        headerClassName: "table-header",
        resizable: false,
        flex: 1,
        filterable: true,
      },
      {
        field: "assignments",
        headerName: "Assignments",
        headerClassName: "table-header",
        resizable: false,
        flex: 1,
        filterable: true,
      },
      {
        field: "new_session_link",
        headerName: "",
        type: "link",
        flex: 1,
        renderCell: params => (
          <a href={params.value} key={params.id} style={{ color: "#3c00f5", textDecoration: "none" }}>
            Create a session
          </a>
        ),
      },
    ],
    []
  )

  return (
    <BraintrustDataGrid
      apiRef={apiRef}
      data={data.students}
      totalCount={data.totalCount}
      transformFunction={transformStudentData}
      columns={columns}
      isLoading={status.isLoading}
      pagination={pagination}
      onPageChange={handlePageChange}
      onFilterChange={handleFilterChange}
      onDensityChange={handleDensityChange}
      toolbar={StudentsDataGridToolbar}
      toolbarProps={{
        filters,
        handleResetFilters,
        handleFilterChange,
      }}
      density={density}
    />
  )
}

export default StudentsTable
