import PersonOff from "@mui/icons-material/PersonOff"
import { DataGrid } from "@mui/x-data-grid"
import React, { useCallback, useMemo, useState } from "react"
import StudentsDataGridToolbar from "./studentsDataGridToolbar"

/**
 * Transforms student data into rows for the DataGrid component.
 *
 * @param {Array} students - Array of student objects.
 * @returns {Array} Transformed student data as rows.
 */
const transformStudentData = students => {
  return students.map((student, index) => ({
    id: index,
    identifier: student.id,
    name: `${student.first_name} ${student.last_name}`,
    email: student.email,
    password: student.password,
  }))
}

// Styles for the DataGrid component
const dataGridStyles = {
  border: "none",
  fontFamily: "'Poppins', sans-serif",
  "--DataGrid-overlayHeight": "300px",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#f5f5f5",
    borderBottom: "2px solid #e0e0e0",
    borderRadius: "8px 8px 0 0",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "600",
    color: "#333",
  },
  "& .MuiTablePagination-displayedRows": {
    padding: 0,
    margin: 0,
  },
  "& .MuiTablePagination-selectLabel": {
    padding: 0,
    margin: 0,
    fontFamily: "'Poppins', sans-serif",
  },
  "& .MuiButton-root": {
    color: "#3c00f5",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#f5f5f5",
  },
  "& .MuiDataGrid-root": {
    borderRadius: "8px",
  },
}

const NoResultsSection = () => {
  return (
    <div className="bg-light d-flex flex-column align-items-center justify-content-center p-4 h-100">
      <PersonOff sx={{ fontSize: 70 }} className="text-muted mb-4" />
      <h3 className="text-center text-muted font-weight-normal" style={{ fontSize: "1.25rem" }}>
        No Students Found
      </h3>
    </div>
  )
}

/**
 * StudentsTable component to display student data in a DataGrid.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.students - Array of student objects.
 * @param {boolean} props.google_sso_enabled - Flag indicating if Google SSO is enabled.
 * @param {Object} props.pagination - Pagination model for the DataGrid.
 * @param {boolean} props.isLoading - Flag indicating if data is being loaded.
 * @param {Function} props.onPageChange - Callback function for page change.
 * @param {Function} props.fetchData - Function to fetch data.
 * @returns {JSX.Element} The StudentsTable component.
 */
const StudentsTable = ({
  students,
  totalCount,
  google_sso_enabled,
  pagination,
  isLoading,
  density,
  onDensityChange,
  onPageChange,
  onFilterChange,
  fetchData,
}) => {
  const [selectedRows, setSelectedRows] = useState([])

  const rows = useMemo(() => transformStudentData(students), [students])

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        headerClassName: "table-header",
        resizable: false,
        flex: 1,
        renderCell: params => (
          <a
            href={`/school_admin/students/${params.row.identifier}`}
            style={{ color: "#3c00f5", textDecoration: "none" }}
          >
            {params.row.name}
          </a>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        headerClassName: "table-header",
        resizable: false,
        flex: 1,
      },
    ],
    []
  )

  const handleRowSelectionModelChange = useCallback(newRowSelectionModel => {
    setSelectedRows(newRowSelectionModel)
  }, [])

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      dataSet="students"
      paginationModel={{
        page: pagination?.page,
        pageSize: pagination?.pageSize,
      }}
      onPaginationModelChange={onPageChange}
      onFilterModelChange={onFilterChange}
      pageSizeOptions={[20, 50, 100]}
      pagination
      filterMode="server"
      paginationMode="server"
      rowCount={totalCount}
      checkboxSelection
      keepNonExistentRowsSelected
      autoHeight
      loading={isLoading}
      density={density}
      onDensityChange={onDensityChange}
      className="school-admins-students-table bg-white"
      getRowId={row => row.id}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      rowSelectionModel={selectedRows}
      sx={dataGridStyles}
      slots={{ toolbar: StudentsDataGridToolbar, noRowsOverlay: NoResultsSection }}
      slotProps={{
        toolbar: {
          rows,
          rowSelectionModel: selectedRows,
          setRowSelectionModel: setSelectedRows,
          fetchData,
          google_sso_enabled: String(google_sso_enabled),
        },
      }}
    />
  )
}

export default StudentsTable
