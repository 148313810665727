import Logo from "@/assets/images/braintrust-logo-black 2.png"
import React, { memo } from "react"
import { Card } from "react-bootstrap"
import QRCode from "react-qr-code"
import NoResultsSection from "../../users/school_admins/NoResults"
import StudentInfo from "./studentInfo"

/**
 * StudentCard component renders a card with student information and a QR code.
 *
 * @param {Object} props - The properties object.
 * @param {Object} props.row - The student data object.
 * @param {string} props.row.name - The name of the student.
 * @param {string} props.row.email - The email of the student.
 * @param {string} props.row.password - The password of the student.
 *
 * @returns {JSX.Element} The rendered StudentCard component.
 */
const StudentCard = memo(({ row }) => {
  if (!row) return <NoResultsSection />

  return (
    <Card className="shadow-none rounded-0 student-card">
      <Card.Body className="p-3 w-100 h-100">
        <Card.Title className="student-card-header">
          <img src={Logo} alt="Braintrust Logo" className="student-card-logo" />
        </Card.Title>
        <Card.Text as="div">
          <div className="d-flex align-items-center w-100">
            <QRCode value="https://btstudent.com" size={40} />
            <p className="student-card-text">
              Go to{" "}
              <a href="https://btstudent.com" target="_blank" rel="noopener noreferrer" className="student-card-link">
                btstudent.com
              </a>{" "}
              and
              <br />
              <span>
                {Boolean(row.google_sso_enabled) && (
                  <>
                    click on <i>`Sign in with Google`</i> <strong>OR</strong>
                    <br />
                  </>
                )}
                type your Email and Password
              </span>
            </p>
          </div>
          <StudentInfo label="Name:" value={row.name} />
          <StudentInfo label="Email:" value={row.email} />
          <StudentInfo label="Password:" value={row.password} />
        </Card.Text>
      </Card.Body>
    </Card>
  )
})

StudentCard.displayName = "StudentCard"

export default StudentCard
