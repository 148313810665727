import React, { useCallback, useMemo, useState } from "react"
import { Button, Col } from "react-bootstrap"
import { useDialogState } from "../../../../hooks/useDialogState"
import Dialog from "../../../blocks/Dialog"
import { PropsInjector } from "../../../PropsInjector"
import { CSVUploadForm } from "./CSVUploadFrom"
import { useCSVUpload } from "./useCSVUpload"
import { useGetPartnershipList } from "./useGetPartnershipList"

const UploadError = ({ status, messages }) => {
  return (
    <Col className="mt-3">
      <h6>{status}</h6>
      {messages.map((message, key) => {
        return <p key={key}>{message}</p>
      })}
    </Col>
  )
}

const Requests = ({ openingId, children, onSuccess }) => {
  const [error, setError] = useState(null)
  const onLoad = useCallback(() => {
    onSuccess()
    setError(null)
  }, [onSuccess])

  const onError = useCallback(errorData => {
    setError(errorData)
  }, [])

  const [isLoading, onSubmit] = useCSVUpload({ openingId, onLoad, onError })

  const [schoolsLoading, schools] = useGetPartnershipList()

  const props = useMemo(
    () =>
      !schools || schoolsLoading
        ? {}
        : {
            schools,
            isLoading,
            onSubmit,
          },
    [schoolsLoading, isLoading, onSubmit, schools]
  )

  if (!schools || schoolsLoading)
    return (
      <div className="h-400-px position-relative">
        <div className="spinner-wrapper">
          <div className="spinner-border text-primary" />
        </div>
      </div>
    )

  return (
    <>
      <PropsInjector props={props}>{children}</PropsInjector>
      {error && <UploadError {...error} />}
    </>
  )
}

const UploadCSV = ({ uploadButtonVariant = "outline-primary", openingId }) => {
  const { closeDialog, dialogState, openDialog } = useDialogState()

  return (
    <div>
      <Button variant={uploadButtonVariant} onClick={openDialog} className="flex-shrink-0">
        Upload CSV
      </Button>

      <Dialog
        size="sm"
        title="Students upload"
        className="opening_create_empty_group_dialog"
        open={dialogState.open}
        onClose={closeDialog}
      >
        <Requests openingId={openingId} onSuccess={closeDialog}>
          <CSVUploadForm />
        </Requests>
      </Dialog>
    </div>
  )
}

export default UploadCSV
