import { useCallback, useEffect, useState } from "react"
import Alerter from "../../../../common/alerter"
import moment from "moment"
import { className } from "../../../../helpers/className"
import useViewport from "../../../../hooks/useViewport"
import { MeetingFormats } from "../../../../constants"
import apiClient from "../../../../common/apiClient"

const useFetchStudent = ({ student_id }) => {
  const { isMobileViewport } = useViewport()
  const [studentData, setStudentData] = useState(null)

  const fetchStudents = useCallback(async () => {
    try {
      const { data } = await apiClient.get(`/api/v2/students/${student_id}`)
      setStudentData(data)
    } catch (error) {
      Alerter.error(`Failed to fetch student data \n${error}`)
    }
  }, [student_id])

  useEffect(() => {
    fetchStudents()
  }, [fetchStudents])

  const nextSession = studentData?.next_session
  const doesNextSessionExist = Object.keys(nextSession || {}).length > 0
  const canStartNextSession = moment(nextSession?.scheduled_at).isBefore(moment().add(5, "minutes"))
  const cardClass = className(isMobileViewport ? "px-3 py-4" : "", doesNextSessionExist ? "next-lesson" : "no-lesson")
  const isLessonspace =  nextSession?.location === MeetingFormats.LessonSpace

  return {
    studentData,
    fetchStudents,
    canStartNextSession,
    cardClass,
    doesNextSessionExist,
    nextSession,
    isLessonspace,
  }
}

export default useFetchStudent
