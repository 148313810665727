import { useCallback, useEffect, useState } from "react"
import { showErrors } from "../../../common/alerter"
import apiClient from "../../../common/apiClient"

export const useGetUpcomingSession = ({ tutorId }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState("")

  const fetchUpcomingSession = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await apiClient.get(`/api/v2/tutors/${tutorId}/upcoming_session`)
      setData(data || "")
    } catch (e) {
      showErrors(e)
    } finally {
      setLoading(false)
    }
  }, [tutorId])

  useEffect(() => {
    if (tutorId) {
      fetchUpcomingSession()
    }
  }, [fetchUpcomingSession, tutorId])

  return { loading, data }
}
