import moment from "moment"

export const formatDate = (date, format = "YYYY-MM-DD") => (date ? moment(date).format(format) : null)

export const parseDate = str => (str ? moment(str).toDate() : null)

export const formatTime = (time, format = "h:mm A") => {
  if (!time) return null
  const m = moment(time, format)
  return m.isValid() ? m.format(format) : null
}

export const parseTime = (str, format = "HH:mm") => (str ? moment(str, format).toDate() : null)

export const formatDateTime = (date, format = "YYYY-MM-DDTHH:mm") => (date ? moment(date).format(format) : null)

export const getStartOfWeek = (date, format = "YYYY-MM-DD") => moment(date).startOf("week").format(format)

export const getEndOfWeek = (date, format = "YYYY-MM-DD") => moment(date).endOf("week").format(format)

export const formatDateKeepZone = (date, format = "YYYY-MM-DD") => (date ? moment.parseZone(date).format(format) : null)

export const formatDateTimeKeepZone = (date, format = "YYYY-MM-DDTHH:mm") =>
  date ? moment.parseZone(date).format(format) : null

export const formatStartOfDayKeepZone = (date, format = "YYYY-MM-DDTHH:mm") =>
  date ? moment.parseZone(date).startOf("day").format(format) : undefined

export const formatEndOfDayKeepZone = (date, format = "YYYY-MM-DDTHH:mm") =>
  date ? moment.parseZone(date).endOf("day").format(format) : null

export const dateToDayOfWeek = d => (d ? moment(d).day() : null)

export const checkDateIsValid = value => value instanceof Date && !isNaN(value.valueOf())

export const minutesToHours = value => {
  const hours = Math.floor(value / 60)
  const minutes = Math.round(value - hours * 60) % 60
  return [hours, minutes]
}

export const getMonths = () => {
  const currentYear = new Date().getFullYear()
  return [
    { name: "January", date: `${currentYear}-01-01`, label: `January` },
    { name: "February", date: `${currentYear}-02-01`, label: `February` },
    { name: "March", date: `${currentYear}-03-01`, label: `March` },
    { name: "April", date: `${currentYear}-04-01`, label: `April` },
    { name: "May", date: `${currentYear}-05-01`, label: `May` },
    { name: "June", date: `${currentYear}-06-01`, label: `June` },
    { name: "July", date: `${currentYear}-07-01`, label: `July` },
    { name: "August", date: `${currentYear}-08-01`, label: `August` },
    { name: "September", date: `${currentYear}-09-01`, label: `September` },
    { name: "October", date: `${currentYear}-10-01`, label: `October` },
    { name: "November", date: `${currentYear}-11-01`, label: `November` },
    { name: "December", date: `${currentYear}-12-01`, label: `December` },
  ]
}

export function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return "th"
  switch (day % 10) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}

export function formatDateString(date) {
  const options = { month: "long" }
  const month = date.toLocaleDateString("en-US", options)
  const day = date.getDate()
  const suffix = getOrdinalSuffix(day)

  return `${month}, ${day}${suffix}`
}
