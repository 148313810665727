import React, { useCallback, useState, useContext } from "react"
import { Form as FForm, Field } from "react-final-form"
import { Col, Row, Form } from "react-bootstrap"
import FormRow from "../../../components/blocks/FormRow"
import TimePicker from "../../../library/timePicker"
import DurationField from "../../../components/blocks/DurationField"
import { PropsInjector } from "../../PropsInjector"
import useViewport from "../../../hooks/useViewport"
import { minutesToHours } from "../../../helpers/dates"
import { DataContext, QueryContext } from "../../../contexts"
import { formatDateTimeKeepZone } from "../../../helpers/dates"
import { createSessionFromReport } from "../configurations/domain"
import { Entities, MeetingFormats } from "../../../constants"
import Condition from "../../Condition"

const colProps = { sm: 17, md: 18, className: "mb-0 form-input-align flex-column" }
const labelProps = { sm: 7, md: 6, className: "v-2 form-label-align px-0" }

const FieldNames = Object.freeze({
  Time: "started_at",
  Duration: "duration",
  Location: "meeting_format",
  LocationComment: "meeting_info",
})

const SyntheticFieldNames = Object.freeze({
  Address: "address",
  Link: "link",
})

const formatTypes = [
  { value: MeetingFormats.PencilSpaces, label: "Pencil Spaces" },
  { value: MeetingFormats.Online, label: "Online" },
  { value: MeetingFormats.Offline, label: "Offline" },
  { value: MeetingFormats.LessonSpace, label: "lessonspace" }
]

const defaultValues = {
  [FieldNames.Time]: "",
  [FieldNames.Duration]: "",
  [FieldNames.Location]: "",
  [FieldNames.LocationComment]: "",
}

const validate =
  ({ maxBillableMin, disableLocation }) =>
  formState => {
    const acc = {}
    const time = formState[FieldNames.Time]
    const duration = formState[FieldNames.Duration]
    const location = formState[FieldNames.Location]
    const address = formState[SyntheticFieldNames.Address]
    const link = formState[SyntheticFieldNames.Link]
    if (!time) acc[FieldNames.Time] = "Required field"
    if (typeof duration !== "number" && !duration) acc[FieldNames.Duration] = "Required field"
    else if (duration <= 0) acc[FieldNames.Duration] = "Duration should be greater than 0"
    else if (maxBillableMin > 0 && duration > maxBillableMin) {
      const [billableHours, billableMinutes] = minutesToHours(maxBillableMin)
      acc[FieldNames.Duration] = `Sessions can be no longer than ${billableHours > 0 ? `${billableHours}h ` : ""}
    ${billableMinutes > 0 ? `${billableMinutes}min` : ""}`
    }
    if (!disableLocation) {
      if (!location) acc[FieldNames.Location] = "Required field"
      if (location === MeetingFormats.Online && !link) acc[SyntheticFieldNames.Link] = "Required field"
      if (location === MeetingFormats.Offline && !address) acc[SyntheticFieldNames.Address] = "Required field"
    }
    return acc
  }

const buildFormResult = (formState, initialValue = {}) => {
  const { [SyntheticFieldNames.Address]: address, [SyntheticFieldNames.Link]: link, ...restState } = formState

  const { [formState[FieldNames.Location]]: comment } = {
    [MeetingFormats.PencilSpaces]: "",
    [MeetingFormats.Online]: link,
    [MeetingFormats.Offline]: address,
  }

  return {
    ...initialValue,
    ...restState,
    [FieldNames.Time]: formatDateTimeKeepZone(formState[FieldNames.Time]),
    [FieldNames.LocationComment]: comment,
  }
}

const SessionDetailsForm = props => {
  const {
    initialValues,
    onSubmit,
    loading,
    disableLocation,
    maxBillableMin,
    pencilSpaceLink,
    date,
    touched,
    children,
  } = props

  const { isMobileViewport } = useViewport()
  const [billableHours, billableMinutes] = minutesToHours(maxBillableMin)

  const submit = data => {
    if (data[FieldNames.Location] === MeetingFormats.PencilSpaces) {
      data[FieldNames.LocationComment] = initialValues[FieldNames.LocationComment]
    }
    onSubmit(data)
  }

  return (
    <FForm
      initialValues={{ ...defaultValues, ...initialValues }}
      validate={validate({ maxBillableMin, disableLocation })}
      onSubmit={submit}
    >
      {({ values, errors, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field type="time" name={FieldNames.Time}>
            {({ input, meta }) => (
              <FormRow label="Time" ColProps={colProps} LabelProps={labelProps} className="mb-4">
                <TimePicker
                  {...input}
                  invalid={(touched || meta.touched) && meta.error}
                  date={date}
                  className="v-2 w-118-px"
                  placeholder="Set time"
                >
                  <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                </TimePicker>
              </FormRow>
            )}
          </Field>

          <Field type="text" name={FieldNames.Duration}>
            {({ input, meta }) => (
              <FormRow label="Duration" ColProps={colProps} LabelProps={labelProps} className="mb-2">
                <DurationField {...input} placeholder="1:30" wrapperClassName="w-118-px" className="v-2" />
                <Form.Control isInvalid={(touched || meta.touched) && meta.error} hidden readOnly />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                {(billableHours > 0 || billableMinutes > 0) && !meta.error?.startsWith("Sessions can be no longer") && (
                  <span className="form-control_caption">
                    Sessions can be no longer than {billableHours > 0 ? `${billableHours}h ` : ""}
                    {billableMinutes > 0 ? `${billableMinutes}min` : ""}
                  </span>
                )}
              </FormRow>
            )}
          </Field>

          <FormRow label="Location" ColProps={{ ...colProps, className: "d-flex mb-0" }} LabelProps={labelProps}>
            <Col>
              <Row
                xs={24}
                className={`mb-2 form-input-align mb-0 ${isMobileViewport ? "flex-column" : "align-items-center"}`}
              >
                <Field type="radio" name={FieldNames.Location}>
                  {({ input }) =>
                    formatTypes
                      .filter(({ value }) => !(value === MeetingFormats.PencilSpaces && !pencilSpaceLink))
                      .map(({ value, label }) => (
                        <Form.Check
                          name={FieldNames.Location}
                          type="radio"
                          className={`v-2 ${isMobileViewport ? "mb-2" : "mr-3"}`}
                          id={`${FieldNames.Location}-${value}`}
                          key={value}
                          label={label}
                          custom
                          {...input}
                          value={value}
                          checked={values[FieldNames.Location] === value}
                          disabled={disableLocation}
                        />
                      ))
                  }
                </Field>
              </Row>
              <Row xs={24} className="mt-2">
                {values[FieldNames.Location] !== MeetingFormats.PencilSpaces ? (
                  <>
                    <Condition when={FieldNames.Location} is={MeetingFormats.Online}>
                      <Field type="text" name={SyntheticFieldNames.Link}>
                        {({ input, meta }) => (
                          <Form.Control
                            className="v-2"
                            placeholder="Link"
                            isInvalid={(touched || meta.touched) && meta.error}
                            disabled={disableLocation}
                            {...input}
                          />
                        )}
                      </Field>
                    </Condition>
                    <Condition when={FieldNames.Location} is={MeetingFormats.Offline}>
                      <Field type="text" name={SyntheticFieldNames.Address}>
                        {({ input, meta }) => (
                          <Form.Control
                            className="v-2"
                            placeholder="Address"
                            isInvalid={(touched || meta.touched) && meta.error}
                            disabled={disableLocation}
                            {...input}
                          />
                        )}
                      </Field>
                    </Condition>
                  </>
                ) : pencilSpaceLink ? (
                  <div className="form-control v-2 overflow-hidden text-no-wrap text-overflow-ellipsis">
                    <a href={pencilSpaceLink} rel="noreferrer" target="_blank">
                      {pencilSpaceLink}
                    </a>
                  </div>
                ) : null}
              </Row>
            </Col>
          </FormRow>
          <hr className="my-4" />
          <Col>
            <Row className="gap-2 align-items-center">
              <PropsInjector props={{ submitDisabled: loading || Object.keys(errors).length > 0 }}>
                {children}
              </PropsInjector>
              {loading && <div className="spinner-border text-primary" />}
            </Row>
          </Col>
        </form>
      )}
    </FForm>
  )
}

export const CreateSessionDetailsForm = ({ onHide, session, maxBillableMin, hookConfig }) => {
  const { scheduleId, schedule } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(hookConfig)

  const [initialValues, setInitialValues] = useState({
    [FieldNames.Time]: "",
    [FieldNames.Duration]: "",
    [FieldNames.Location]: schedule.meeting_format || MeetingFormats.Online,
    [SyntheticFieldNames.Link]: schedule.meeting_info || "",
    [SyntheticFieldNames.Address]: schedule.address || "",
  })

  const onSubmit = formState => {
    setInitialValues(formState)
    const session = buildFormResult(formState)
    request({
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
      },
      data: { session },
      onSuccess: onHide,
    })
  }

  const Footer = useCallback(
    ({ submitDisabled }) => {
      return (
        <>
          <button className="btn btn-primary" type="submit" disabled={submitDisabled}>
            Create
          </button>
          <button className="btn btn-link btn-link-default" type="button" onClick={onHide}>
            Dismiss
          </button>
        </>
      )
    },
    [onHide]
  )
  return (
    <SessionDetailsForm
      date={session.started_at}
      loading={loading}
      pencilSpaceLink={schedule.pencil_space_link}
      initialValues={initialValues}
      maxBillableMin={maxBillableMin}
      onSubmit={onSubmit}
    >
      <Footer />
    </SessionDetailsForm>
  )
}

export const EditSessionDetailsForm = ({ onHide, session, maxBillableMin, hookConfig }) => {
  const { schedule } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(hookConfig)

  const [initialValues, setInitialValues] = useState({
    [FieldNames.Time]: new Date(formatDateTimeKeepZone(session.started_at)),
    [FieldNames.Duration]: session.duration,
    [FieldNames.Location]: session.meeting_format,
    [SyntheticFieldNames.Link]: session.meeting_info || schedule.meeting_info,
    [SyntheticFieldNames.Address]: schedule.address || "",
  })

  const onSubmit = formState => {
    setInitialValues(formState)
    const data = buildFormResult(formState, { ids: [session.id] })
    request({
      data,
      onSuccess: onHide,
    })
  }

  const Footer = useCallback(
    ({ submitDisabled }) => {
      return (
        <>
          <button className="btn btn-primary" type="submit" disabled={submitDisabled}>
            Save
          </button>
          <button className="btn btn-link btn-link-default" type="button" onClick={onHide}>
            Cancel
          </button>
        </>
      )
    },
    [onHide]
  )
  return (
    <SessionDetailsForm
      date={session.started_at}
      loading={loading}
      initialValues={initialValues}
      maxBillableMin={maxBillableMin}
      onSubmit={onSubmit}
      pencilSpaceLink={schedule.pencil_space_link}
      touched
    >
      <Footer />
    </SessionDetailsForm>
  )
}

// todo - update request when pencil_spaces will be integrated
export const UnplannedSessionDetailsForm = ({ onHide, session, maxBillableMin }) => {
  const { groupSessionId, schedule } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(createSessionFromReport)

  const [initialValues, setInitialValues] = useState({
    [FieldNames.Time]: new Date(formatDateTimeKeepZone(session.started_at)),
    [FieldNames.Duration]: session.ps_duration,
    [FieldNames.Location]: MeetingFormats.PencilSpaces,
    [FieldNames.LocationComment]: schedule.meeting_info || "",
  })
  const onSubmit = async data => {
    setInitialValues(data)
    await request({
      entitiesIds: {
        [Entities.GroupSessions]: groupSessionId,
      },
      data: {
        group_session: {
          ...data,
          [FieldNames.Time]: formatDateTimeKeepZone(data[FieldNames.Time]),
          [FieldNames.Location]: MeetingFormats.PencilSpaces,
          [FieldNames.LocationComment]: schedule.meeting_info || "",
        },
      },
      onSuccess: onHide,
    })
  }

  const Footer = useCallback(({ submitDisabled }) => {
    return (
      <button className="btn btn-primary" type="submit" disabled={submitDisabled}>
        Confirm
      </button>
    )
  }, [])
  return (
    <>
      <Row className="mb-4">
        <span>Pencil Spaces tracked an unplanned session time.</span>
        <br />
        {/* <span>Please confirm or dismiss the tracked data.</span> */}
        <span>Please confirm the tracked data.</span>
      </Row>
      <SessionDetailsForm
        date={session.started_at}
        loading={loading}
        initialValues={initialValues}
        maxBillableMin={maxBillableMin}
        onSubmit={onSubmit}
        pencilSpaceLink={schedule.pencil_space_link}
        disableLocation
        touched
      >
        <Footer />
      </SessionDetailsForm>
    </>
  )
}
