import { Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { styled } from "@mui/material/styles"
import React from "react"
import { commonStyles } from "./Filters"

export const FilterSelect = styled(Select)(({ theme }) => ({
  ...commonStyles,
  display: "block",
  width: "100%",
  fontSize: "1rem",
  fontWeight: 400,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "25px",
  border: `1px solid ${theme.palette.divider}`,
  "&:hover": {
    borderColor: theme.palette.grey[400],
  },
  "&:focus": {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
  },
}))

export const FilterLabel = styled(InputLabel)(({ theme }) => ({
  ...commonStyles,
  color: theme.palette.text.secondary,
  fontSize: "0.85714rem",
  fontWeight: 400,
  lineHeight: 2.25,
  padding: theme.spacing(0, 1),
}))
const FilterSelectInput = ({ label, name, value, onChange, options }) => (
  <Grid item xs={12} sm={6} md={3}>
    <FilterLabel htmlFor={`filter-by-${name}`}>{label}</FilterLabel>
    <FilterSelect
      label={label}
      name={name}
      value={value || ""}
      onChange={onChange}
      aria-label={label}
      sx={{ width: "100%" }}
    >
      {options.map((option, index) => {
        const [optionName, optionValue] = option
        return (
          <MenuItem value={optionValue} key={`${name}-${index}`}>
            {optionValue ? optionName : <b>{optionName}</b>}
          </MenuItem>
        )
      })}
    </FilterSelect>
  </Grid>
)

export default FilterSelectInput
