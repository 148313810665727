import React, { useCallback, useContext, useMemo } from "react"
import { FieldNames, formValidate } from "./constants"
import { QueryContext } from "../../../../contexts"
import { updateConnection } from "../../configurations/domain"
import { ConnectionForm } from "./form"
import { useBalkError } from "../../../../hooks/useBalkError"

export const EditConnectionForm = ({ connections, schedule, onCancel, onSave, rowsClassName, buttonsClassName, children }) => {
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(updateConnection)
  const handler = useBalkError({
    onUpdate: onSave,
    successMessage: "Tutors successfully updated",
    buildErrorMessage: error => {
      const record = connections.find(connection => error.record.id === connection.id)
      if (!record) return
      return `Connection for ${record.tutor.first_name} ${record.tutor.last_name} hasn't been updated`
    },
  })

  const multipleValues = useMemo(() => {
    const values = {
      [FieldNames.StartDate]: false,
      [FieldNames.EndDate]: false,
      [FieldNames.MaxPaidNoShows]: false,
      [FieldNames.Rate]: false,
      [FieldNames.Position]: false,
    }
    if (connections.length === 1) return values
    connections.reduce((con1, con2) => {
      if (!values[FieldNames.StartDate]) values[FieldNames.StartDate] = con1.start_on !== con2.start_on
      if (!values[FieldNames.EndDate]) values[FieldNames.EndDate] = con1.end_on !== con2.end_on
      if (!values[FieldNames.MaxPaidNoShows])
        values[FieldNames.MaxPaidNoShows] = con1.max_paid_noshows !== con2.max_paid_noshows
      if (!values[FieldNames.Rate]) values[FieldNames.Rate] = con1.rate !== con2.rate
      if (!values[FieldNames.Position]) values[FieldNames.Position] = con1.position_level !== con2.position_level
      return con2
    })
    return values
  }, [connections])

  const initialValues = useMemo(() => {
    const [connection] = connections
    return {
      [FieldNames.StartDate]: multipleValues[FieldNames.StartDate] ? null : connection.start_on,
      [FieldNames.EndDate]: multipleValues[FieldNames.EndDate] ? null : connection.end_on,
      [FieldNames.MaxPaidNoShows]: multipleValues[FieldNames.MaxPaidNoShows] ? "" : connection.max_paid_noshows,
      [FieldNames.Rate]: multipleValues[FieldNames.Rate] ? "" : connection.rate,
      ...(!multipleValues[FieldNames.Position] ? { [FieldNames.Position]: connection.position_level } : null),
    }
  }, [connections, multipleValues])

  const onSubmit = useCallback(
    () => {
      const connectionId = connections?.[0]?.id;
      const scheduleId = schedule?.id;

      if (!connectionId || !scheduleId) {
        return;
      }

      request({
        url: `/api/schedules/${scheduleId}/connections/${connectionId}`,
        urlBuilderConfig: null,
        onSuccess: handler,
      })
    },
    [connections, handler, request, schedule?.id]
  )

  return (
    <ConnectionForm
      multipleValues={multipleValues}
      initialValues={initialValues}
      validate={formValidate({ checkPositionField: connections.length === 1, multipleValues })}
      showPositionField={connections.length === 1}
      loading={loading}
      rowsClassName={rowsClassName}
      buttonsClassName={buttonsClassName}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      {children}
    </ConnectionForm>
  )
}
