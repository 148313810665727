import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import Alerter from "../../../common/alerter"
import apiClient from "../../../common/apiClient"
import Dialog from "../../blocks/Dialog"
import AuthenticationForm from "./AuthenticationForm"

const AuthenticationModal = ({ endpoint, setOpen }) => {
  const [password, setPassword] = useState("")
  const [open, setModalOpen] = useState(true)

  const onSubmit = async e => {
    e.preventDefault()
    try {
      await apiClient.get(endpoint, { params: { password } })
      setOpen(false)
      setModalOpen(false)
    } catch (error) {
      Alerter.error(error.response.data.error)
    }
  }

  return (
    <Dialog
      open={open}
      className="edit_tutor_dialog"
      keyboard={false}
      style={{
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
      }}
      showCloseButton={false}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>Authentication</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AuthenticationForm password={password} setPassword={setPassword} />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={onSubmit} disabled={!password}>
          Submit
        </button>
      </Modal.Footer>
    </Dialog>
  )
}

export default AuthenticationModal
