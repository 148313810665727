import PersonOff from "@mui/icons-material/PersonOff"
import { DataGrid } from "@mui/x-data-grid"
import PropTypes from "prop-types"
import React, { useCallback, useMemo, useState } from "react"

/**
 * Transforms data into rows for the DataGrid component.
 *
 * @param {Array} data - Array of data objects.
 * @param {Function} transformFunction - Function to transform data objects into rows.
 * @returns {Array} Transformed data as rows.
 */
const transformData = (data, transformFunction) => {
  return data.map(transformFunction)
}

// Styles for the DataGrid component
const dataGridStyles = {
  border: "none",
  fontFamily: "'Poppins', sans-serif",
  "--DataGrid-overlayHeight": "300px",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#f5f5f5",
    borderBottom: "2px solid #e0e0e0",
    borderRadius: "8px 8px 0 0",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "600",
    color: "#333",
  },
  "& .MuiTablePagination-displayedRows": {
    padding: 0,
    margin: 0,
  },
  "& .MuiTablePagination-selectLabel": {
    padding: 0,
    margin: 0,
    fontFamily: "'Poppins', sans-serif",
  },
  "& .MuiButton-root": {
    color: "#3c00f5",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#f5f5f5",
  },
  "& .MuiDataGrid-root": {
    borderRadius: "8px",
  },
}

const NoResultsSection = () => {
  return (
    <div className="bg-light d-flex flex-column align-items-center justify-content-center p-4 h-100">
      <PersonOff sx={{ fontSize: 70 }} className="text-muted mb-4" />
      <h3 className="text-center text-muted font-weight-normal" style={{ fontSize: "1.25rem" }}>
        No Data Found
      </h3>
    </div>
  )
}

/**
 * BraintrustDataGrid component to display data in a DataGrid.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.data - Array of data objects.
 * @param {Function} props.transformFunction - Function to transform data objects into rows.
 * @param {Array} props.columns - Array of column definitions.
 * @param {boolean} props.isLoading - Flag indicating if data is being loaded.
 * @param {Object} props.pagination - Pagination model for the DataGrid.
 * @param {Function} props.onPageChange - Callback function for page change.
 * @param {Function} props.onFilterChange - Callback function for filter change.
 * @param {JSX.Element} props.toolbar - Custom toolbar component for the DataGrid.
 * @returns {JSX.Element} The BraintrustDataGrid component.
 */
const BraintrustDataGrid = ({
  data,
  transformFunction,
  columns,
  density,
  isLoading,
  pagination,
  onPageChange,
  onFilterChange,
  onDensityChange,
  toolbar,
  totalCount,
  toolbarProps,
  apiRef,
}) => {
  const { page, pageSize } = pagination
  const [selectedRows, setSelectedRows] = useState([])

  const rows = useMemo(() => transformData(data, transformFunction), [data, transformFunction])

  const handleRowSelectionModelChange = useCallback(newRowSelectionModel => {
    setSelectedRows(newRowSelectionModel)
  }, [])

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        dataSet="students"
        paginationModel={{
          page,
          pageSize,
        }}
        onPaginationModelChange={onPageChange}
        onFilterModelChange={onFilterChange}
        pagination
        filterMode="server"
        paginationMode="server"
        checkboxSelection
        keepNonExistentRowsSelected
        autoHeight
        loading={isLoading}
        density={density}
        onDensityChange={onDensityChange}
        className="school-admins-students-table bg-white"
        getRowId={row => row.id}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        rowSelectionModel={selectedRows}
        sx={dataGridStyles}
        rowCount={totalCount}
        pageSizeOptions={[20, 50, 100]}
        onPageChange={onPageChange}
        disableSelectionOnClick
        slots={{ toolbar, noRowsOverlay: NoResultsSection }}
        slotProps={{
          toolbar: {
            rows,
            rowSelectionModel: selectedRows,
            setRowSelectionModel: setSelectedRows,
            ...toolbarProps,
          },
        }}
      />
    </div>
  )
}

BraintrustDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  transformFunction: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  pagination: PropTypes.object,
  onPageChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  google_sso_enabled: PropTypes.bool,
}

export default BraintrustDataGrid
