import apiClient from "@/common/apiClient"
import { Grid, InputBase, InputLabel } from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { memo, useCallback, useEffect, useState } from "react"
import FilterSelectInput from "./FilterSelectInput"

export const commonStyles = {
  fontFamily: "'Poppins', sans-serif",
  borderRadius: "8px",
  transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
}

export const FilterInput = styled(InputBase)(({ theme }) => ({
  ...commonStyles,
  display: "block",
  width: "100%",
  padding: theme.spacing(1, 2),
  fontSize: "1rem",
  fontWeight: 400,
  height: " 4rem",
  lineHeight: 2.25,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "25px",
  border: `2px solid ${theme.palette.divider}`,
  "&:hover": {
    borderColor: theme.palette.grey[400],
  },
  "&:focus": {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
  },
}))

export const FilterLabel = styled(InputLabel)(({ theme }) => ({
  ...commonStyles,
  color: theme.palette.text.secondary,
  fontSize: "0.85714rem",
  fontWeight: 400,
  lineHeight: 2.25,
  padding: theme.spacing(0, 1),
}))

const INITIAL_FILTERS = {
  schools: [],
  groups: [],
  grades: [],
}

const FilterInputs = ({ filter, handleFilterChange, handleResetFilters }) => {
  const [filters, setFilters] = useState(INITIAL_FILTERS)

  const fetchFilters = useCallback(async () => {
    const { data } = await apiClient("/api/v2/admins/students/filters")
    setFilters(data)
  }, [])

  useEffect(() => {
    fetchFilters()
  }, [fetchFilters])

  return (
    <Grid container spacing={2} sx={{ alignItems: "flex-end" }}>
      <Grid item xs={12} sm={6} md={3}>
        <FilterLabel htmlFor="filter-by-name">Filter by Name</FilterLabel>
        <FilterInput
          label="Filter by Name"
          name="name"
          type="search"
          value={filter?.name || ""}
          onChange={handleFilterChange}
          aria-label="Filter by Name"
        />
      </Grid>
      <FilterSelectInput
        label="Filter by School"
        name="school_id"
        value={filter?.school_id}
        onChange={handleFilterChange}
        options={filters.schools}
      />
      <FilterSelectInput
        label="Filter by Group"
        name="group_id"
        value={filter?.group_id}
        onChange={handleFilterChange}
        options={filters.groups}
      />
      <FilterSelectInput
        label="Filter by Grade"
        name="grade"
        value={filter?.grade}
        onChange={handleFilterChange}
        options={filters.grades}
      />
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <button className="btn btn-outline-primary" onClick={handleResetFilters} aria-label="Reset Filters">
          Reset Filters
        </button>
      </Grid>
    </Grid>
  )
}

export default memo(FilterInputs)
